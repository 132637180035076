import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CometChatUI } from "../cometchat-pro-react-ui-kit/CometChatWorkspace/src";
export default function Home() {
    const handleLogout = () => {
        sessionStorage.removeItem('Auth Token');
        navigate('/login')
    }
    let navigate = useNavigate();
    useEffect(() => {
        let authToken = sessionStorage.getItem('Auth Token')
        console.log(authToken)
        if (authToken) {
            navigate('/home')
        }

        if (!authToken) {
            navigate('/login')
        }
    }, [])
    return (
        <div>
            Home Page
            <div style={{width: '800px', height:'800px' }}>
                <CometChatUI />
            </div>
            <button onClick={handleLogout}>Log out</button>
        </div>
    )
}