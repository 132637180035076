import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyAjp_jG_gopSvLHGWlx5xO6ASUFtQhANIc",
    authDomain: "ibefriend-uk.firebaseapp.com",
    projectId: "ibefriend-uk",
    storageBucket: "ibefriend-uk.appspot.com",
    messagingSenderId: "1025885958921",
    appId: "1:1025885958921:web:ca64b614e16847a3c159d8",
    measurementId: "G-XPCVLJLQES"
};

export const app = initializeApp(firebaseConfig);